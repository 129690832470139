import { Environment } from "src/app/interfaces";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


export const environment: Environment = {
  production: true,
  name: "production",
  api: "https://vrcardioexplorer-default-rtdb.firebaseio.com/prod",
  firebaseStorageMaxSize: 2147483648, //bytes 2147483648 => 2GB
  availableImageFormats: ["PNG", "JPG", "JPEG", "PNG", "GIF", "SVG", "WEBP", "BMP", "ICO", "TIFF", "TIF", "PSD"],
  availablePNGFormat: ["PNG"],
  availableModelFormats: ["GLB", "GLTF"],
  availableVideoFormats: ["MP4"],
  availableCSVFormats: ["CSV"],
  availableDicomFormat: ["DCM"],
  deviceID: "vrcardio",
  brokerIP: "20.19.250.215",
  brokerPort: 1883,
  userAmount: {
    basicAmount: "1000.00",
    professionalAmount_1Month: "1000.00",
    professionalAmount_3Month: "2500.00",
    professionalAmount_1Year: "5000.00"
  },
  firebase: {
    apiKey: "AIzaSyBefnW0YDeF6p7Q5l_Hr-Dt4hKwa98FyT8",
    authDomain: "vrcardioexplorer.firebaseapp.com",
    databaseURL: "https://vrcardioexplorer-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "vrcardioexplorer",
    storageBucket: "vrcardioexplorer.appspot.com",
    messagingSenderId: "289145635458",
    appId: "1:289145635458:web:c32d6b4d4d836dc0292a9b",
    // measurementId: "G-8V611FENGS"
  },
  // Token API calls
  obtaintoken: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/obtaintoken',
  refreshtoken: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/refreshtoken',
  // Django API calls
  vrcardiosessions: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiosessions',
  vrcardiointerventions: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiointerventions',
  vrcardiolobbies: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiolobbies',
  vrcardiopatients: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiopatients',
  vrcardiocreatelobbies: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiocreatelobbies',
  vrcardioupdatelobbies: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardioupdatelobbies',
  vrcardiodeletelobbies: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiodeletelobbies',
  vrcardiocreatepatient: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiocreatepatient',
  vrcardiocreatesession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/vrcardiocreatesession',
  // Base vr-cardioexplore calls
  getsimplifieddeviceid: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsimplifieddeviceid',
  getactivesessions: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getactivesessions',
  deleteactivesession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleteactivesession',
  addactivesession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/addactivesession',
  updatesession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatesession',
  updatesessionnote: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatesessionnote',
  createsession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createsession',
  getsinglesession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsinglesession',
  getsinglepatient: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsinglepatient',
  getsessions: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsessions',  
  getsingleuserdata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsingleuserdata',
  getmanycardio3d: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanycardio3d',
  getcardio3d: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getcardio3d',
  getmanycardio3dcategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanycardio3dcategories',
  getmanynews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanynews',
  getnew: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getnew',
  getmanyecg: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyecg',
  getecg: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getecg',
  deleteecg: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleteecg',
  createecg: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createecg',
  updateecg: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateecg',
  updateecgviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateecgviews',
  getmanynewscategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanynewscategories',
  getmanyecgcategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyecgcategories',
  getmanydevelopment: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanydevelopment',
  getdevelopment: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getdevelopment',
  getmanydevelopmentcategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanydevelopmentcategories',
  getmanyinteractiveimages: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyinteractiveimages',
  getinteractiveimage: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getinteractiveimage',
  getmanyinteractiveimagescategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyinteractiveimagescategories',
  getmanyrealcases: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyrealcases',
  getrealcase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getrealcase',
  getmanyrealcasescategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyrealcasescategories',
  getmanyscienceinsights: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyscienceinsights',
  getscienceinsight: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getscienceinsight',
  getmanyscienceinsightscategories: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyscienceinsightscategories',
  getteam: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getteam',
  getsinglevestdas: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsinglevestdas',
  createvest: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createvest',
  deletevest: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletevest',
  getactivevests: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getactivevests',
  getvestdas: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getvestdas',
  createvestdas: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createvestdas',
  getallusers: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getallusers',
  getmanypipelinereport: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanypipelinereport',
  getconfig: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getconfig',
  setconfig: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/setconfig',
  resetwifivest: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/resetwifivest',
  deletepatient: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletepatient',
  deleteusercontent: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleteusercontent',
  getpatients: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getpatients',
  deletevestsessionrecorded: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletevestsessionrecorded',
  savebasesequences: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/savebasesequences',
  getmanyvrcardiolicence: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanyvrcardiolicence',
  updatepatientdasdata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatepatientdasdata',
  encryptuser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptuser',
  encryptstats: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptstats',
  encryptdicoms: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptdicoms',
  encryptcartocases: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptcartocases',
  encryptecgs: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptecgs',
  encryptinteractiveimages: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptinteractiveimages',
  encryptcardio3d: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptcardio3d',
  encryptdevelopment: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptdevelopment',
  encryptrealcases: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptrealcases',
  encryptnews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptnews',
  encryptbase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptbase',
  encryptbasicuser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptbasicuser',
  encryptvestdata: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptvestdata",
  encryptvestdas: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptvestdas",
  encryptsessiondas: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptsessiondas",
  encryptpatients: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/encryptpatients",
  getsoftware: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsoftware',  
  vrcardioupdatevests: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatevestdata',
  deleteuser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleteuser',
  createmqttconection: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createmqttconectionaux',
  stopmqttconection: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/stopmqttconection',
  launchpipeline: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/launchpipeline',
  launchreport: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/launchreport',
  deletevestdas: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletevestdas',
  deletesession: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletesession',
  createpatient: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createpatient',
  updatevestdasdata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatevestdasdata',
  deleteuserfromauth: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleteuserfromauth',
  createmqtterror: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createmqtterror',
  updatesessionsarraydata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatesessionsarraydata',
  deletesessionfrompatient: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletesessionfrompatient',
  updateuserdata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateuserdata',

  getuser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getuser',
  getbaseuser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getbaseuser',
  getuserdata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getuserdata',
  registeruser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/registeruser',
  getencrypteduser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getencrypteduser',
  getencrypteduserresult: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getencrypteduserresult',
  sendMail: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/sendMail',
  createstats: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createstats',
  deletecardio3d: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletecardio3d',
  createcardio3d: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createcardio3d',
  deletenew: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletenew',
  createnew: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createnew',
  deletedevelopment: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletedevelopment',
  createdevelopment: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createdevelopment',
  deleterealcase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleterealcase',
  createrealcase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createrealcase',
  deletescienceinsight: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletescienceinsight',
  createscienceinsight: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createscienceinsight',
  addnewtouser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/addnewtouser',
  updatenew: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatenew',
  updaterealcase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updaterealcase',
  updatedevelopment: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatedevelopment',
  updatecardio3d: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatecardio3d',
  updateinteractiveimage: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateinteractiveimage',
  deleteinteractiveimage: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deleteinteractiveimage',
  createinteractiveimage: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createinteractiveimage',
  updatenewviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatenewviews',
  updatecardio3dviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatecardio3dviews',
  updatedevelopmentviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatedevelopmentviews',
  updatescienceinsightsviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatescienceinsightsviews',
  updateinteractiveimageviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateinteractiveimageviews',
  updaterealcaseviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updaterealcaseviews',
  updatescienceinsight: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatescienceinsight',
  getwebstats: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getwebstats',
  deletecartocase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletecartocase',
  createcartocase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createcartocase',
  updatecartocase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatecartocase',
  updatecartocaseviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatecartocaseviews',
  getmanycartocases: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanycartocases',
  getcartocase: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getcartocase',
  getvestdata: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getvestdata',
  getpipelinereport: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getpipelinereport',
  getupdatetable: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getupdatetable',
  updateuserpassword: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateuserpassword',
  updateuseremail: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateuseremail',
  updatedicom: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatedicom',
  getdicom: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getdicom',
  getmanydicom: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getmanydicom',
  deletedicom: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletedicom',
  createdicom: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/createdicom',
  updatedicomviews: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updatedicomviews',
  getquiz: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getquiz",
  updateQuizRecord: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateQuizRecord",
  getquizrecord: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getquizrecord",
  sendsms: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/sendsms",
  addimagetosession: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/addimagetosession",
  getsessionsindexes: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsessionsindexes",
  addassociatedfilestosession: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/addassociatedfilestosession",
  getsessionfiles: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsessionfiles",
  deletesessionfiles: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/deletesessionfiles",
  checklogin: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/checklogin",
  checkuser: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/checkuser",
  pipelinetoken: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/pipelinetoken",
  getcurrentsessionid: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getcurrentsessionid",
  signalstatus: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/signalstatus",  
  checkmqttconnection: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/checkmqttconnectionaux",
  getsinglevestdasfrommac: "https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsinglevestdasfrommac",
  
  updateuser: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/updateuser',
  saverestorepasswordcode: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/saverestorepasswordcode',
  checkrestorecode: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/checkrestorecode',
  trackUser: true,
  timeToTrackUser: 60000,
  genreateJSONEachFrme: false,
  stopDataRecording: true,
  sampleRate: 250,
  showUpdateTableMessage: false,
  initializeCornestone: true,
  swaggerURL: "https://firebasestorage.googleapis.com/v0/b/vrcardioexplorer.appspot.com/o/swagger%2Fswagger.json?alt=media&token=1b979fcc-27e0-4e1e-81bf-14f0357ff33e",

  //Cardio 3D variables
  colorSliderMultiplier: 0.015,
  frameRateSliderUpdate: 15,
  ambientLight_MaxValue: 2,
  backgroundColor_MaxValue: 2,
  roughness_MaxValue: 2,
  normalScale_MaxValue: 2,
  metalness_MaxValue: 2,
  displacementScale_MaxValue: 2,

  pipelineIp: {
    visualCardioDev:"http://193.147.50.21:5000", // Pipeline no repo segmentation
    visualCardioProd:"http://193.147.50.21:5000", // Pipeline repo segmentation
    vrCardioExploreDev:"http://193.147.50.21:5001", // Pipeline no repo segmentation
    vrCardioExploreProd:"http://193.147.50.21:5001", // Pipeline repo segmentation
  },
}; 